* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: -apple-system, "思源黑体", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

svg {
	font-family: -apple-system, "思源黑体", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
	width: 100%;
	height: 100%;
	background: #fff;
	color: #333;
}

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px;
	height: 48px;
	line-height: 48px;
	background: #f6f7f8;
}

.header-left {
	display: flex;
	align-items: center;
}

.header img {
	margin-right: 24px;
	height: 20px;
}

.header span {
	color: #172991;
}

.header-avator {
	width: 32px;
	height: 32px;
	background: #ddd;
	border-radius: 32px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.banner {
	display: flex;
	align-items: flex-end;
	padding: 0 10%;
	height: 192px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center 35%;
}

.banner h2 {
	margin-bottom: 16px;
	font-size: 28px;
	color: #fff;
	text-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.app-section,
.nortify-section {
	margin-top: 24px;
	margin-bottom: 24px;
	padding: 0 10%;
}

.app-section h3,
.nortify-section h3 {
	font-weight: normal;
}

.app-wrapper {
	margin-top: 24px;
	display: flex;
}

.app-wrapper a {
	color: #333;
}

.app-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
	margin-bottom: 8px;
	width: 96px;
	height: 96px;
	transition: 0.3s ease-in-out;
	color: #999;
	cursor: pointer;
}

.app-block img {
	width: 40px;
	margin-bottom: 8px;
}

.app-block p {
	font-size: 12px;
	margin: 0;
}

.app-block:hover {
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	font-weight: bold;
	color: #172991;
}

.nortify-section table {
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	font-size: 14px;
	text-align: left;
	padding: 20px 20px 20px 0;
	border-bottom: 1px solid #e8e8e8;

}

th {
	font-size: 12px;
	color: #999;
	font-weight: normal;
}

.fixed-width-cell {
	min-width: 80px;
}

.ant-card-body {
	padding: 12px;
}

@media only screen and (max-width: 600px) {

	.banner,
	.app-section,
	.nortify-section {
		padding: 0 16px;
	}

	.banner {
		height: 120px;
	}

	.app-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.app-wrapper a {
		color: #333;
	}

	.app-block {
		margin-right: 0;
		margin-bottom: 0;
		flex: 0 0 25%;
	}

	.app-block img {
		width: 40px;
	}

	.app-block p {
		font-size: 10px;
	}

	th,
	td {
		font-size: 12px;
	}

	.ant-card-body p {
		height: 64px;
	}
}

.ant-card-cover {
	padding:10px;
}